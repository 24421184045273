<template>
  <div>
    <div
      class="item"
      v-if="typedValues"
    >
      <div
        v-for="(itemList, itemListType) in typedValues"
        :key="itemListType"
      >
        <div
          class="conItem"
          v-if="itemList && itemList.length"
        >
          <div class="tags">
            <div class="title">
              <span>{{ typeNameByKey(itemListType) }}：</span>
            </div>
            <div class="tagList">
              <el-tag
                closable
                v-for="(item, i) in itemList"
                :key="itemListType + '-' + i"
                :type="invaild_insterest.includes(item.id)?'danger':''"
                @close="removeValue(item)"
              >
                {{ item.name }}
              </el-tag>
              <!-- <el-tag closable>bbbbb</el-tag> -->
            </div>
            <div class="tools">
              <span>
                <el-tooltip
                  effect="dark"
                  :content="moveToTip"
                  placement="top"
                >
                  <i
                    class="el-icon-position"
                    @click="moveTo(itemListType)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="删除该分类"
                  placement="top"
                >
                  <i
                    class="el-icon-delete"
                    @click="removeType(itemListType)"
                  ></i>
                </el-tooltip>
              </span>
            </div>
          </div>
          <div
            class="tags suggest"
            v-if="suggestValues && suggestValues[itemListType] && suggestValues[itemListType].length > 0"
          >
            <span class="title">建议：</span>
            <div
              class="tagList"
              v-if="suggestValues[itemListType] && suggestValues[itemListType].length"
            >
              <span
                v-for="suggest in suggestValues[itemListType]
                  .filter((v) => itemList.every((k) => k.name !== v.name))
                  .splice(0, 60)"
                :key="suggest.uniqueid"
                style="cursor: pointer"
              >
                <el-tooltip
                  :enterable="false"
                  :content="formatSize(suggest.audience_size)"
                  placement="top-end"
                >
                  <el-tag @click="addValue(suggest)">{{ suggest.name }}</el-tag>
                </el-tooltip>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="type"
            placeholder="请选择"
            style="width: 100%"
            value-key="key"
            @change="selectType"
          >
            <el-option
              :label="typeItem.name"
              :value="typeItem.key"
              v-for="(typeItem, index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="14">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            multiple
            filterable
            :value="computedValues[type]"
            value-key="uniqueid"
            v-if="typeItem.key === type"
            :remote-method="debouncedRemoteQueryOptions"
            remote
            @change="valuesChange"
            v-for="(typeItem, index) in typeList"
            :key="index"
          >
            <el-option
              :label="item.name"
              ref="items"
              :value="item"
              v-for="(item, inde) in options[type]"
              :key="inde"
            >
              <span style="float: left">{{ item.name }}</span>
              <p
                style="
                  float: right;
                  width: 30%;
                  color: #8492a6;
                  font-size: 13px;
                  margin-right: 20px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span style="margin-right: 10px">规模：{{ formatSize(item.audience_size) }}</span
                ><span>{{ item.typeDesc }}</span>
              </p>
            </el-option>
            <div
              slot="empty"
              class="empty"
            >
              <i class="iconfont icon-wushuju"></i>
              <p>暂无数据</p>
            </div>
          </el-select>
        </el-col>
        <el-col :span="2" class="leading-10">
          <el-checkbox
            v-model="zhCN"
            style="padding-left: 5px"
            >中文</el-checkbox
          >
        </el-col>
        <el-col
          :span="4"
          v-if="canSetCommonSave"
        >
          <common-use
            :list="typedValues"
            @setCommonUseData="setCommonUseData"
            savedType="GEO_REGION"
            :geos="[]"
          ></common-use>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { targetingSearch } from '@/api/creatAd';
import commonUse from './commonUse.vue';
const _ = require('lodash');

export default {
  components: {
    commonUse,
  },
  name: 'FlexibleSpec',
  props: {
    Thirdloginuserid: {
      type: String,
    },
    accountId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    moveToTip: String,
    flexibleIndex: Number,
    contentIndex: Number,
    flexibelIndex: Number,
    canSetCommonSave: {
      type: Boolean,
      default: true,
    },
    invaild_insterest:{
      type:Array,
      default:()=>[]
    }
  },
  created() {
    this.debouncedRemoteQueryOptions = _.debounce(this.remoteQueryOptions, 500);
  },
  data() {
    return {
      zhCN: true,
      valueAll: [],
      typeList: [
        // {key: 'interestsuggestion', name: '荐词'},
        { key: 'all', name: '全部' },
        { key: 'interests', name: '兴趣' },
        { key: 'behaviors', name: '行为' },
        { key: 'custom_audience', name: '自定义受众' },
        { key: 'education_schools', name: '学校' },
        { key: 'education_statuses', name: '学历' },
        { key: 'family_statuses', name: '家庭状况' },
        { key: 'income', name: '收入' },
        { key: 'industries', name: '行业' },
        { key: 'life_events', name: '生活纪事' },
        { key: 'relationship_statuses', name: '感情状况' },
        { key: 'adworkemployer', name: '雇主' },
        { key: 'adworkposition', name: '职务' },
      ],
      type: 'all',
      suggestValues: {},
      options: {
        all: [],
        interests: [],
        behaviors: [],
        custom_audience: [],
        education_schools: [],
        education_statuses: [],
        family_statuses: [],
        income: [],
        industries: [],
        life_events: [],
        relationship_statuses: [],
        adworkemployer: [],
        adworkposition: [],
      },
      inputValues: {
        all: [],
        interests: [],
        behaviors: [],
        custom_audience: [],
        education_schools: [],
        education_statuses: [],
        family_statuses: [],
        income: [],
        industries: [],
        life_events: [],
        relationship_statuses: [],
        adworkemployer: [],
        adworkposition: [],
      },
      optLength: 0,
      commonList: [],
    };
  },
  watch: {
    inputValues: {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },
  },
  computed: {
    computedValues() {
      this.initValue();
      return this.inputValues;
    },
    typedValues() {
      return this.parsedValue(this.inputValues);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.accountId && this.Thirdloginuserid) {
        this.remoteQueryOptions('');
      }
    });
  },
  methods: {
    setCommonUseData(val, isRemove) {
      // 快速选择常用 受众
      let content = JSON.parse(val);
      for (let key in content) {
        let item = content[key];
        item.forEach((ele) => {
          if (isRemove) {
            this.removeValue(ele);
          } else {
            this.addValue(ele);
          }
        });
      }
    },
    resetOptions() {
      this.options = {
        all: [],
        interests: [],
        behaviors: [],
        custom_audience: [],
        education_schools: [],
        education_statuses: [],
        family_statuses: [],
        income: [],
        industries: [],
        life_events: [],
        relationship_statuses: [],
        adworkemployer: [],
        adworkposition: [],
      };
    },
    formatSize(size) {
      if (!parseInt(size)) {
        return '0';
      }
      return (parseInt(size) / 10000).toFixed(2) + '万';
    },
    onValueChange() {
      let res = {};
      let parsedV = this.parsedValue(this.inputValues);
      // res['all'] = this.allValue(this.inputValues);
      for (let k in parsedV) {
        res[k] = parsedV[k];
      }
      this.$emit('input', res);
    },
    changedWithType(nv, ov, type, added) {
      let nvtype = [];
      let ovtype = [];
      if (nv && nv[type]) nvtype = nv[type];
      if (ov && ov[type]) ovtype = ov[type];
      if (added) {
        return _.differenceBy(nvtype, ovtype, 'uniqueid');
      } else {
        return _.differenceBy(ovtype, nvtype, 'uniqueid');
      }
    },
    initValue() {
      let nv = this.value || [];
      // console.log(nv);
      let oldV = _.cloneDeep(this.inputValues);
      for (let k in this.inputValues) {
        this.inputValues[k] = [];
      }
      if (nv) {
        for (let k in nv) {
          if (this.inputValues[k] === undefined) {
            this.inputValues[k] = [];
          }
          if (nv[k]) {
            nv[k].forEach((item) => {
              if (!this.inputValues[k].some((s) => s.uniqueid === item.uniqueid)) {
                this.inputValues[k].push(item);
              }
              if (!this.inputValues['all'].some((s) => s.uniqueid === item.uniqueid)) {
                this.inputValues['all'].push(item);
              }
            });
          }
        }
      } else {
        for (let k in this.inputValues) {
          this.inputValues[k] = [];
        }
      }
      let newV = this.inputValues;
      this.suggestionAll(newV, oldV);
      // console.log('set---value', this.inputValues)
    },
    parsedValue(value) {
      let res = {};
      let selfVals = value;
      for (let type1 in selfVals) {
        for (let i in selfVals[type1]) {
          let find = selfVals[type1][i];
          let key = find.type;
          if (!res[key]) {
            res[key] = [find];
          } else {
            if (!res[key].some((m) => m.uniqueid === find.uniqueid)) {
              res[key].push(find);
            }
          }
        }
      }
      // console.log(res)
      return res;
    },
    moveTo(type) {
      this.$emit('move-to', type, this.removeType(type), this.flexibleIndex);
    },
    removeType(type) {
      let removed = [];
      var selfVals = this.inputValues;
      for (let type1 in selfVals) {
        selfVals[type1]
          .filter((n) => n.type === type)
          .forEach((n) => {
            if (!removed.some((it) => it.uniqueid === n.uniqueid)) removed.push(n);
          });
        selfVals[type1] = selfVals[type1].filter((n) => n.type !== type);
      }
      // console.log("removed:",removed)
      this.onValueChange();
      return removed;
    },
    typeNameByKey(key) {
      let find = null;
      this.typeList.some((n, i) => {
        if (n.key === key) {
          find = n;
          return true;
        }
      });
      return find ? find.name : '';
    },
    // loadInitData() {
    //     var type = this.type;
    // console.log("loadInitData", this.options[type])
    //     if (!this.options[type] || this.options[type].length === 0) {
    //         this.remoteQueryOptions('');
    //     }
    // },
    remoteQueryOptions(query) {
      var type = this.type;
      if (!query && this.options[type] && this.options[type].length > 0) {
        return;
      }
      let responsDataProcess = this.responsDataProcess;

      targetingSearch(
        {
          accountId: this.accountId,
          targetingType: this.type === 'all' ? '' : this.type,
          query: query,
          zhCN: this.zhCN,
        },
        this.Thirdloginuserid,
      ).then((res) => {
        let resultList = responsDataProcess(res.data);
        if (!resultList || resultList.length === 0) {
          if (query) {
            this.$message('没有关于“' + query + '”的数据！');
          }
        } else {
          this.appendOptionValues(resultList);
          // this.sortOptionValuesByQuery(query, resultList);
        }
      });
    },
    sortOptionValuesByQuery(query, youxianlist) {
      var type = this.type;
      if (query) {
        let toSort = this.options[type] || [];
        let matched = [];
        let matchedYouxian = [];
        let notMatched = [];
        let notMatchedYouxian = [];
        toSort.forEach((n) => {
          let youxian = false;
          if (youxianlist && youxianlist.some((m) => m.uniqueid === n.uniqueid)) {
            youxian = true;
          }
          if (n.name.match(query)) {
            if (youxian) {
              matchedYouxian.push(n);
            } else {
              matched.push(n);
            }
          } else {
            if (youxian) {
              notMatchedYouxian.push(n);
            } else {
              notMatched.push(n);
            }
          }
        });
        this.options[type] = [].concat(matchedYouxian, notMatchedYouxian, matched, notMatched);
        this.$nextTick(() => {
          if (this.$refs['items'][this.optLength]) {
            this.$refs['items'][this.optLength].$el.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    },
    appendOptionValues(vals) {
      var type = this.type;
      this.optLength = this.options[type].length;
      this.resetOptions();
      if (vals && vals.length > 0) {
        if (!this.options[type]) {
          this.options[type] = vals;
        } else {
          let cachedAdded = _.differenceBy(vals, [], 'uniqueid').reverse();
          cachedAdded.forEach((n) => this.options[type].unshift(n));
        }
        vals.reverse().forEach((n, i) => {
          if (!this.options[n.type]) {
            this.options[n.type] = [];
          }
          if (!this.options[n.type].some((m) => m.uniqueid === n.uniqueid)) {
            this.options[n.type].unshift(n);
          }
        });
      }
    },
    removeValue(item) {
      let selfVals = this.inputValues;
      for (let type1 in selfVals) {
        for (let i in selfVals[type1]) {
          let find = selfVals[type1][i];
          // console.log(find.type, item.type, find.id, item.id, find.name, item.name)
          if (find.type === item.type && find.id === item.id) {
            selfVals[type1].splice(i, 1);
          }
        }
      }
      this.onValueChange();
    },
    responsDataProcess(items) {
      if (items) {
        items.forEach((n) => {
          let type = n.type;
          n.type = type.key;
          n.typeDesc = type.desc;
        });
      } else {
        items = [];
      }
      return items;
    },
    addValue(item) {
      let selfVals = this.inputValues;
      let oldV = _.cloneDeep(selfVals);
      for (let type1 in selfVals) {
        if (type1 !== 'all' && type1 !== item.type) continue;
        if (selfVals[type1].some((n) => n.uniqueid === item.uniqueid)) continue;
        selfVals[type1].push(item);
      }
      this.onValueChange();
      // this.suggestionAll(selfVals, oldV);
    },
    // 选择分类
    selectType(v) {
      if (v == 'custom_audience') {
        this.remoteQueryOptions('');
      }
    },
    valuesChange(items) {
      console.log(items);
      let oldV = _.cloneDeep(this.inputValues);
      this.inputValues[this.type] = items ? items : [];
      let allItems = this.inputValues['all'];
      if (this.type === 'all') {
        for (let k in this.inputValues) {
          if (k !== this.type) {
            let tmpItems = this.inputValues[k];
            this.inputValues[k] = tmpItems.filter((a) => allItems.some((b) => a.uniqueid === b.uniqueid));
          }
        }
      } else {
        this.inputValues['all'] = allItems.filter(
          (a) => a.type !== this.type || this.inputValues[this.type].some((b) => a.uniqueid === b.uniqueid),
        );
      }
      this.suggestionAll(this.inputValues, oldV);
      this.onValueChange();
    },
    suggestionAll(newV, oldV) {
      for (let k in newV) {
        if (k !== 'all') {
          // let tmpAdd = this.changedWithType(newV, oldV, k, true);
          let tmpAdd = newV[k];
          // let tmpDel=this.changedWithType(oldV,newV,k,false);
          if (tmpAdd && tmpAdd.length > 0) {
            // console.log("tmpAdd", tmpAdd);
            this.suggestion(tmpAdd);
          }
        }
      }
    },
    suggestion(items) {
      if (items && items.length > 0) {
        let node = items[items.length - 1];
        // console.log(node.id, node.name, node.type.desc)
        let suggestValues = this.suggestValues;
        let k = node.type;
        if (k === 'interests') {
          let responsDataProcess = this.responsDataProcess;
          let names = items.filter((m) => k === m.type).map((m) => m.id);

          targetingSearch(
            {
              accountId: this.accountId,
              targetingType: 'interestsuggestion',
              query: names.join(','),
              zhCN: this.zhCN,
            },
            this.Thirdloginuserid,
          ).then((res) => {
            this.$set(this.suggestValues, k, responsDataProcess(res.data));
            // console.log(k + ':' + (suggestValues[k].map((m, i) => m.name).join(',')));
          });
        } else {
          suggestValues[k] = this.options[k] || [];
        }
        // for(let k in suggestValues){
        // console.log(k+':'+(suggestValues[k].map((m,i)=>m.name).join(',')));
        // }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.conItem {
  padding: 10px;

  .tags {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    position: relative;
    &.suggest {
      border-radius: 5px;
      border: 1px solid #ddd;
      background: rgba($color: #eee, $alpha: 0.6);
      // border: 1px solid #c8e0ff;
      // background: #f8fbff;
    }

    .tools {
      position: absolute;
      top: -10px;
      right: 0px;

      i {
        font-size: 12px;
        margin: 5px;
        cursor: pointer;
      }
    }

    .title {
      flex: 80px 0 0;
      margin-right: 10px;
      text-align: right;
      color: #666;
      font-weight: bold;
    }

    .el-tag {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .tagList {
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
