<template>
  <div>
    <el-row>
      <!-- <el-col :span="18" style="text-align:right"> -->
      <el-dropdown
        trigger="click"
        @command="handleCommand"
        v-if="myRegionGroup.length"
        :hide-on-click="false"
      >
        <span class="el-dropdown-link"> {{ name }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
        <el-dropdown-menu
          slot="dropdown"
          style="max-height: 300px; overflow-y: auto"
        >
        <div style="margin: 0 10px;">
          <el-input placeholder="查询" v-model="filerKey" size="mini"></el-input>
        </div>
          <!--  :class="[ids.includes(item.id)?'activeSelect':'']" -->
          <el-dropdown-item
            v-for="item in myRegionGroupShow"
            :class="[ids.includes(item.id) ? 'activeSelect' : '']"
            :key="item.id"
            :command="item"
          >
            <div class="regionGroupList">
              <span>{{ item.label }}</span>
              <span
                ><i
                  class="el-icon-close"
                  @click.stop="deleteCommonuse(item.id)"
                ></i
              ></span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- </el-col> -->
      <!-- <el-col :span="6" style="text-align:center"> -->
      <el-button
        type="text"
        @click="saveAlert"
        :disabled="btnDisabled"
        >存为常用</el-button
      >
      <!-- </el-col> -->
    </el-row>
    <!-- 保存设置 -->
    <el-dialog
      title="保存为快捷选项"
      :visible.sync="saveCommonUse"
      width="600px"
      append-to-body
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="名称："
            prop="name"
          >
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="saveCommonUse = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { targetingCommonQuery, targetingCommonSave, targetingCommonDel } from '@/api/creatAd';
export default {
  props: {
    list: {
      type: Object,
      default: () => {},
    },
    savedType: {
      type: String,
      default: 'GEO_REGION',
    },
    geos: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'Object',
    },
    account: {
      type: String,
      default: '',
    },
    targetUserId: {
      type: String,
      default: '',
    },
  },
  watch: {
    list(val) {
      if (Object.keys(val).length >= 2) {
        this.testData = JSON.parse(JSON.stringify(val));
      }
    },
  },
  filters: {
    disGroup(v, geos) {
      let disAble = false;
      // console.log(this);
      v.map((item) => {
        if (that.geos.includes(item.country_code)) {
          disAble = true;
        }
        // item.country_code
      });
      return disAble;
    },
  },
  data() {
    return {
      ruleForm: {},
      myRegionGroup: [],
      saveCommonUse: false,
      ids: [],
      testData: {},
      filerKey: ''
    };
  },
  computed: {
    name() {
      return this.ids.length
        ? this.myRegionGroup
            .filter((v) => this.ids.includes(v.id))
            .map((k) => k.label)
            .join(',')
        : '常用';
    },
    btnDisabled() {
      // 存为常用按钮是否禁用
      return Object.keys(this.list)?.length === 0;
    },
    saleInfo() {
      // 商品信息
      return this.$store.state.num.audiencesNeedData;
    },
    myRegionGroupShow(){
      if(this.filerKey){
        return this.myRegionGroup.filter(item=> item.label.includes(this.filerKey))
      }
      return this.myRegionGroup
    }
  },
  methods: {
    getList() {
      // 初始化常用列表
      let request = {
        label: '', //兴趣词标签
        pageNo: 0,
        pageSize: 100,
      };
      targetingCommonQuery(JSON.stringify(request)).then((res) => {
        if (res.code === 0) {
          this.myRegionGroup = res.data.content;
        }
      });
    },
    saveAlert() {
      // if(!this.list.length){
      //     this.$message({
      //         type:'warning',
      //         message:'请选选择要保存的省份'
      //     })
      //     return false;
      // }
      this.saveCommonUse = true;
    },
    // 选择常用
    handleCommand(v) {
      let isRemove = false;
      if (this.ids.includes(v.id)) {
        let index = this.ids.indexOf(v.id);
        this.ids.splice(index, 1);
        isRemove = true;
      } else {
        this.ids.push(v.id);
      }
      this.$emit('setCommonUseData', v.flexibleSpec, isRemove);
    },
    saveBtn() {
      // 存为常用
      const that = this;
      let request = {
        productId: that.saleInfo.productId,
        saleId: that.saleInfo.saleId,
        label: that.ruleForm.name,
        flexibleSpec: JSON.stringify(that.list),
      };
      targetingCommonSave(JSON.stringify(request)).then((res) => {
        if (res.code === 0) {
          this.$message.success('已存为常用');
          this.getList();
          this.saveCommonUse = false;
        }
      });
    },
    deleteCommonuse(id) {
      let request = {
        id,
      };
      targetingCommonDel(JSON.stringify(request)).then((res) => {
        if (res.code === 0) {
          this.getList();
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown {
  margin: 0 10px;
}
.el-dropdown-menu__item:focus.activeSelect,
.el-dropdown-menu__item.activeSelect {
  background: #ecf5ff;
  color: #66b1ff;
}
.el-dropdown-menu__item.active,
.el-dropdown-menu__item:focus {
  background: transparent;
  color: #606266;
}
.regionGroupList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
}
</style>
